import { render, staticRenderFns } from "./UsersEdit.vue?vue&type=template&id=b4e1bcaa&scoped=true&"
import script from "./UsersEdit.vue?vue&type=script&lang=js&"
export * from "./UsersEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4e1bcaa",
  null
  
)

export default component.exports