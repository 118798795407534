<template>
    <div class="UsersEdit full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <v-form ref="form">
                <div class="content-area pa-5 flex-grow-1 bg-white">
                <v-row class="d-flex ">
                    <v-col cols="6" class="pa-0 pr-4">
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('system.userManagement.username') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <text-input inputName="editUser-username" @onInputFocus="onInputFocus" :readonly="!editable || !checkUserRights('userManagementEdit')" :class="{'noAction': !editable || !checkUserRights('userManagementEdit')}" :rules="[v => !!v || $t('validation.required'), rules.alphanumericName, rules.maxLengthName]"/>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('system.userManagement.firstName') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <text-input inputName="editUser-firstname" @onInputFocus="onInputFocus" :readonly="!editable || !checkUserRights('userManagementEdit')" :class="{'noAction': !editable || !checkUserRights('userManagementEdit')}" :rules="[v => !!v || $t('validation.required'), rules.alphabeticName, rules.maxLengthName]"/>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('system.userManagement.lastName') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <text-input inputName="editUser-lastname" @onInputFocus="onInputFocus" :readonly="!editable || !checkUserRights('userManagementEdit')" :class="{'noAction': !editable || !checkUserRights('userManagementEdit')}" :rules="[v => !!v || $t('validation.required'), rules.alphabeticName, rules.maxLengthName]"/>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('system.userManagement.uid') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <text-input inputName="editUser-uid" @onInputFocus="onInputFocus" onlyNumbers :readonly="!editable || !checkUserRights('userManagementEdit')" :class="{'noAction': !editable || !checkUserRights('userManagementEdit')}" :rules="[rules.numericValue]"/>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('system.userManagement.group') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <v-select :items="activeGroups" item-text="name" item-value="id" v-model="editUser.groupId" class="editUser-groupId-dropdown" outlined hide-details="auto" attach :readonly="!editable || !checkUserRights('userManagementEdit')" :class="{'noAction': !checkUserRights('userManagementEdit')}" :rules="[v => !!v || $t('validation.required')]"/>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('general.status') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <v-select :items="selectStatus" item-text="name" item-value="value" v-model="editUser.status" class="editUser-status-dropdown" outlined hide-details="auto" attach :readonly="!checkUserRights('userManagementEdit')" :class="{'noAction': !checkUserRights('userManagementEdit')}" />
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('system.userManagement.lastLogin') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly">{{ this.editUser.lastLogin }}</p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('system.userManagement.failedLogins') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly">{{ this.editUser.failedLogins }}</p>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6" class="pa-0 pl-4">
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('system.userManagement.newPassword') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <text-input inputName="password" @onInputFocus="onInputFocus" type="password" :readonly="!editable || !checkUserRights('userManagementEdit')" :class="{'noAction': !editable || !checkUserRights('userManagementEdit')}" :rules="[rules.maxLengthValue, rules.alphanumericSpecCharValue, rules.password, rules.passwordSpecialChar]"/>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('system.userManagement.passwordRepeat') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <text-input inputName="passwordrepeat" @onInputFocus="onInputFocus" type="password" :readonly="!editable || !checkUserRights('userManagementEdit')" :class="{'noAction': !editable || !checkUserRights('userManagementEdit')}" />
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <switch-on-off name="editUser-forcePasswordChange" :disabled="!editable || !checkUserRights('userManagementEdit')" :class="{'active': forcePasswordChange}" class="mr-4"/>
                            </v-col>
                            <v-col class="col-8">
                                <p>{{ $t('system.userManagement.passwordChange') }}</p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('system.userManagement.passwordExpire') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <text-input inputName="editUser-validity" @onInputFocus="onInputFocus" onlyNumbers label="system.userManagement.days" :readonly="!editable || !checkUserRights('userManagementEdit')" :class="{'noAction': !editable || !checkUserRights('userManagementEdit')}" :rules="[rules.validity]"/>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
            </v-form>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="print" :successCheck="showPrintSuccess" :progress="showPrintProgress" :class="{'disabled': printDisabled || !checkUserRights('userManagementPrint')}" @footerButtonClick="print"></footer-button>
            <footer-button buttonFunction="delete" :class="{'disabled': !editable || editUser.id == null || !checkUserRights('userManagementDelete')}" @footerButtonClick="deleteUser"></footer-button>
            <footer-button buttonFunction="cancel" @footerButtonClick="goToUsers"></footer-button>
            <footer-button buttonFunction="save" :successCheck="showSaveSuccess" :progress="showSaveProgress" :class="{'disabled': saveDisabled || !checkUserRights('userManagementEdit')}" @footerButtonClick="save"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import TextInput from '@/components/TextInput.vue'
import SwitchOnOff from '@/components/SwitchOnOff.vue'
import FooterButton from '@/components/FooterButton.vue'

import {mapGetters, mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'UsersEdit',
    components: {
        TextInput,
        SwitchOnOff,
        FooterButton,
    },
    props: {
    },
    data () {
        return {
            selectStatus: [
                {
                    'name': this.$t('general.active'),
                    'value': 'Active'
                },{
                    'name': this.$t('general.inactive'),
                    'value': 'Inactive'
                },{
                    'name': this.$t('system.userManagement.locked'),
                    'value': 'Locked'
                }
            ],
            saveDisabled: true,
            showSaveProgress: false,
            showSaveSuccess: false,
            showPrintProgress: false,
            showPrintSuccess: false,
            originalUser: {},
            rules: {
                alphanumericName: v => {
                    if (v){
                        let alphanumeric = /^[ A-Za-z0-9]+$/;
                        return alphanumeric.test(v) || `${this.$t('validation.pleaseEnterValidName')}. ${this.$t('validation.noSpecialChar')}`;
                    } else return true;
                },
                alphabeticName: v => {
                    if (v){
                        let alphanumeric = /^[ A-Za-z.-]+$/;
                        return alphanumeric.test(v) || `${this.$t('validation.pleaseEnterValidName')}`;
                    } else return true;
                },
                maxLengthName: v => {
                    if (v){
                        return v.length < 24 || `${this.$t('validation.chooseShorterName')}`;
                    } else return true;
                },
                maxLengthValue: v => {
                    if (v){
                        return v.length < 24 || `${this.$t('validation.chooseShorterValue')}`;
                    } else return true;
                },
                alphanumericSpecCharValue: v => {
                    if (v){
                        let alphanumericSpecChar = /^[A-Za-z0-9*%&\-_/+?!@#.]+$/;
                        return alphanumericSpecChar.test(v) || `${this.$t('validation.pleaseEnterValidValue')}`;
                    } else return true;
                },
                numericValue: v => {
                    if (v){
                        let numeric = /^[0-9]+$/;
                        return numeric.test(v) || `${this.$t('validation.pleaseEnterValidValue')}`;
                    } else return true;
                },
                password: v => {
                    if (this.editUser.id == null){
                        return !!v || this.$t('validation.required');
                    } else return true;
                },
                passwordSpecialChar: v => {
                    if (v){
                        if (this.editUser.groupId != null){
                            //get group settings of selected group
                            let group = this.grouplist.find(group => {
                                return group.id == this.editUser.groupId;
                            });
                            //check if complexPassword activated
                            if (group.complexPassword){
                                let specialChar = /[*%&\-_/+?!@#.]/;
                                let capitalChar = /[A-Z]/;
                                let number = /[0-9]/;
                                if (specialChar.test(v) && capitalChar.test(v) && number.test(v) && (v.length >= 8)){
                                    return true;
                                } else return `${this.$t('validation.complexPassword')}`;
                            } else return true;
                        } else {
                            return this.$t('validation.groupSelectionMissing');
                        }
                    } else return true;
                },
                validity: v => {
                    if (v){
                        return v < 367 || `${this.$t('validation.maximum')}: 366, 0 = ${this.$t('validation.unlimited')}`;
                    } else {
                        return `${this.$t('validation.maximum')}: 366, 0 = ${this.$t('validation.unlimited')}`;
                    }
                },
            }
        }
    },
    computed: {
        ...mapState([
            'editUser',
            'password',
            'passwordrepeat',
            'grouplist'
        ]),
        ...mapGetters({
            activeGroups: 'activeGroups',
        }),
        editable(){
            return this.editUser.editable;
        },
        printDisabled(){
            if (this.editUser.id != null){
                return false;
            } else {
                return true;
            }
        },
        forcePasswordChange(){
            return this.$store.state.editUser.forcePasswordChange;
        },
    },
    methods:{
        goToUsers(){
            this.$router.push('users');
        },
        print(){
            this.showPrintProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/admin/user/print', { 'id': parseInt(this.editUser.id) }])
            .then(response =>{
                this.showPrintProgress = false;
                if (response.status === 200){
                    this.showPrintSuccess = true;
                    setTimeout(()=>{
                        this.showPrintSuccess = false;
                    }, 800)
                }
            });
        },
        deleteUser(){
            this.$store.dispatch('postAxiosNoSetter', ['/admin/user/delete', { 'id': parseInt(this.editUser.id) }])
            .then(response=>{
                if (response.status === 200){
                    this.goToUsers();
                }
            })
        },
        save(){
            this.$refs.form.validate();
            if (this.$refs.form.validate()){
                this.showSaveProgress = true;
                if (this.passwordrepeat === this.password){
                    if (this.editUser.id != null){
                        let User = {}
                        //optional
                        if (this.editUser.username != this.originalUser.username && this.editUser.username != ""){
                            User.username = this.editUser.username;
                        }
                        if (this.editUser.firstname != this.originalUser.firstname && this.editUser.firstname != ""){
                            User.firstname = this.editUser.firstname;
                        }
                        if (this.editUser.lastname != this.originalUser.lastname && this.editUser.lastname != ""){
                            User.lastname = this.editUser.lastname;
                        }
                        if (this.editUser.groupId != this.originalUser.groupId){
                            User.groupId = this.editUser.groupId;
                        }
                        if (this.password != null && this.password != ""){
                            User.password = this.password;
                        }
                        if (this.editUser.validity != this.originalUser.validity && this.editUser.validity != ""){
                            User.validity = parseInt(this.editUser.validity);
                        }
                        if (this.editUser.forcePasswordChange != this.originalUser.forcePasswordChange){
                            User.forcePasswordChange = this.editUser.forcePasswordChange;
                            // User.forcePasswordChange = 'test Error'
                        }
                        if (this.editUser.status != this.originalUser.status){
                            User.status = this.editUser.status;
                        }
                        if (this.editUser.uid != this.originalUser.uid){
                            User.uid = parseInt(this.editUser.uid);
                            if (isNaN(User.uid)){
                                User.uid = null;
                            }
                        }
                        if (Object.keys(User).length === 0 && User.constructor === Object){
                            this.showSaveProgress = false;
                            this.showSaveSuccess = true;
                            this.watcher();
                            setTimeout(()=>{
                                this.saveDisabled = true;
                                this.showSaveSuccess = false;
                            }, 800)
                        } else {
                            //must send
                            User.id = parseInt(this.editUser.id);
                            this.$store.dispatch('postAxiosNoSetter', ['admin/user/edit', User])
                                .then(response=>{
                                    this.showSaveProgress = false;
                                    if (response.status === 200){
                                        this.showSaveSuccess = true;
                                        this.$store.commit('emptyPassword');
                                        this.watcher();
                                        setTimeout(()=>{
                                            this.saveDisabled = true;
                                            this.showSaveSuccess = false;
                                        }, 800)
                                    }
                                })
                        }
                    } else {
                        let User = {
                            //must send
                            "username": this.editUser.username,
                            "firstname": this.editUser.firstname,
                            "lastname": this.editUser.lastname,
                            "uid": parseInt(this.editUser.uid),
                            "groupId": parseInt(this.editUser.groupId),
                            "password": this.password,
                            "validity": parseInt(this.editUser.validity),
                            "forcePasswordChange": this.editUser.forcePasswordChange,
                            "status": this.editUser.status
                        }
                        if (isNaN(User.uid)){
                            User.uid = null;
                        }
                        this.$store.dispatch('postAxiosNoSetter', ['admin/user/new', User])
                            .then(response=>{
                                this.showSaveProgress = false;
                                if (response.status === 200){
                                    this.editUser.id = response.data.id;
                                    this.showSaveSuccess = true;
                                    this.$store.commit('emptyPassword');
                                    this.watcher();
                                    setTimeout(()=>{
                                        this.saveDisabled = true;
                                        this.showSaveSuccess = false;
                                    }, 800)
                                }
                            })
                    }
                } else {
                    this.showSaveProgress = false;
                    this.$store.commit('emptyPassword');
                    this.inputName = '';
                    this.$store.commit('newError', this.$t('alerts.passwordMatch'));
                }
            }
        },
        watcher(){
            //copy editUser to originalUser for comparison
            this.originalUser = JSON.parse(JSON.stringify(this.editUser));

            let username = this.$watch('$store.state.editUser.username', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let firstname = this.$watch('$store.state.editUser.firstname', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let lastname = this.$watch('$store.state.editUser.lastname', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let uid = this.$watch('$store.state.editUser.uid', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let groupId = this.$watch('$store.state.editUser.groupId', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let password = this.$watch('$store.state.password', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let validity = this.$watch('$store.state.editUser.validity', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let forcePasswordChange = this.$watch('$store.state.editUser.forcePasswordChange', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let status = this.$watch('$store.state.editUser.status', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            const stopWatch = function(){
                username();
                firstname();
                lastname();
                uid();
                groupId();
                password();
                validity();
                forcePasswordChange();
                status();
            }
        },
        // Keyboard functions ------------------ //
        onInputFocus(input, onlyNumbers) {
            this.$emit('onInputFocus', [input.target.id, onlyNumbers])
            this.$vuetify.goTo('#'+input.target.id, {duration: 100, offset: 80, container: ".content-area"});
        },
    },
    mixins: [mixins],
    created() {
        this.$emit("resetInput");
        //empty password & repeat
        this.$store.commit('emptyPassword');

        this.watcher();
    },
    beforeRouteLeave (to, from, next) {
        if(this.saveDisabled){
            next()
        } else {
            this.$confirm({message: this.$t('alerts.unsavedChanges'), show: true, cancelText: this.$t('general.cancel'), continueText: this.$t('general.continue') })
                .then(() => {
                    // continue
                    next();
                })
                .catch(() => {
                    // cancel
                });
        }
    },
}
</script>
<style scoped lang="scss">
</style>